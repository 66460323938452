:root {
  --brand: #FCB03C;
  --text: #262840;
  --border:  #DDD;
}

html{
  height: 100%;
}
body{
  height: 100%;
}
a{
  text-decoration: none;
}

.col-brand{
  color:var(--brand);
}

.col-text-link{
  color:var(--text);
  transition: all 0.3s ease;
}
.col-text-link:hover{
  color:var(--brand);
}

.font-weight-bold{
  font-weight: 700;
}
.font-weight-normal{
  font-weight: 400 !important;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}

.rounded{
  border-radius: 4px;
}

.pointer{
  cursor: pointer;
  transition: all 0.5s ease;
}

.hl{
  transition: all 0.3s ease;
}

.hl:hover{
  color: var(--brand);
}

.pointer:hover{
  border-color: var(--brand);
}

.l-s-3{
  letter-spacing: 3px;
}

.l-s-1{
  letter-spacing: 1px;
}

.heading-h1{
  font-size: 21px !important;
}

.nowrap{
  white-space: nowrap;
}

.border-desktop{
  border-radius: 4px;
  border: none;
}

.blink-me {
  animation: blinker 1s linear infinite;
}

.sml-btn{
  border:solid 1px #DDD;
  padding: 3px 6px;
  border-radius: 18px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (min-width: 640px) { 

  .heading-h1{
    font-size: 34px !important;
  }


  .border-desktop{
    border-radius: 4px;
    border: solid 1px var(--border)
  }
 }

 .form-control{
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  border: none;
  -webkit-appearance: none;
  background: transparent;
  color: inherit;
  width: 100%;
  padding: 9px;
  font-weight: 600;
  margin: 0;
  border: 1px solid #DDD;
  border-radius: 4px;
 }